<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-chip v-bind="props">
        <v-icon color="green" class="mr-2" size="14">mdi-circle</v-icon>
        <span v-if="!smAndDown">{{ activeNetwork.name }}</span>
        <v-icon>mdi-chevron-down</v-icon>
      </v-chip>
    </template>
    <v-list>
      <template v-for="network in NETWORKS" :key="network.networkId">
        <v-list-item
          v-if="network.networkId !== activeNetwork.networkId"
          :value="network.networkId"
          @click="changeActiveNetwork(network.networkId)"
        >
          <v-list-item-title>
            {{ network.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { useNetwork } from "@/composables/useNetwork";
import { NETWORKS } from "@/utils/constants";
const { activeNetwork, changeActiveNetwork } = useNetwork();
import { useDisplay } from "vuetify/lib/framework.mjs";
const { smAndDown } = useDisplay();
</script>
