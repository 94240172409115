<template>
  <div v-if="activeAccount" class="d-flex align-center">
    <v-chip v-if="walletInfo" class="mr-4 d-none d-md-flex">
      <v-icon color="blue" class="mr-2" size="14">mdi-wallet</v-icon>
      {{ walletInfo?.name }}
    </v-chip>
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-chip v-bind="props">
          <v-avatar start loading>
            <v-progress-circular
              v-if="scanningForAccounts"
              indeterminate
              color="primary"
            />
            <v-img
              v-else
              :src="`https://avatars.z52da5wt.xyz/${activeAccount}`"
            />
          </v-avatar>

          {{ smAndDown ? "" : formatAddress(activeAccount) }}
          <strong class="px-2">
            {{ smAndDown ? balance?.shorten() : balance?.prettify() }} AE
          </strong>
          <v-icon>mdi-chevron-down</v-icon>
        </v-chip>
      </template>
      <v-list>
        <v-list-item :value="activeAccount">
          <template v-slot:prepend>
            <v-avatar start size="24">
              <v-img :src="`https://avatars.z52da5wt.xyz/${activeAccount}`" />
            </v-avatar>
          </template>
          <v-list-item-title>
            {{ formatAddress(activeAccount) }}
          </v-list-item-title>
        </v-list-item>
        <template v-for="address in accounts" :key="address">
          <v-list-item
            v-if="activeAccount !== address"
            :value="address"
            @click="setActiveAccount(address)"
          >
            <template v-slot:prepend>
              <v-avatar start size="24">
                <v-img :src="`https://avatars.z52da5wt.xyz/${address}`" />
              </v-avatar>
            </template>
            <v-list-item-title>
              {{ formatAddress(address) }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item @click="disconnectWallet()">
          <v-list-item-title class="text-red"> Disconnect </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="accountDetailDialog" max-width="600px">
      <v-card>
        <v-card-title>
          {{ formatAddress(activeAccount) }}
        </v-card-title>
        <v-card-text>
          <div><strong>Wallet:</strong> {{ walletInfo?.name }}</div>

          <div>
            <strong>Network ID:</strong>
            {{ walletInfo?.networkId }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" @click="disconnectWallet()"> Disconnect </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import { useAccounts } from "@/stores/accounts";
import { useWalletConnect } from "@/composables/useWalletConnect";
import { formatAddress } from "@/utils/address";
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useAeppSdk } from "@/composables/aeppSdk";
import { Decimal } from "@/libs/decimal";
const { smAndDown } = useDisplay();

const { setActiveAccount } = useAccounts();
const { activeAccount, accounts } = storeToRefs(useAccounts());

const { disconnectWallet, walletInfo, scanningForAccounts } =
  useWalletConnect();

const { getAeSdk } = useAeppSdk();

const accountDetailDialog = ref(false);
const balance = ref(Decimal.ZERO);
const timer = ref();

async function loadBalance() {
  const sdk = await getAeSdk();
  if (sdk && activeAccount.value) {
    const loadedBalance = await sdk.getBalance(activeAccount.value);
    balance.value = Decimal.fromBigNumberString(loadedBalance);
  } else {
    balance.value = Decimal.ZERO;
  }
}
onMounted(() => {
  timer.value = setInterval(() => {
    loadBalance();
  }, 5000);
});

onUnmounted(() => {
  clearInterval(timer.value);
});
</script>
