<template>
  <div v-if="!loading" class="d-flex justify-space-around">
    <v-skeleton-loader
      type="ossein"
      width="140"
      height="80"
    ></v-skeleton-loader>
  </div>
  <div v-else class="chart-container">
    <div ref="chartContainer" class="lw-chart" />
    <div v-if="data?.timeframe" class="timeframe-indicator">
      {{ data.timeframe }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watch, defineProps } from "vue";
import { ISeriesApi, LineSeriesPartialOptions } from "lightweight-charts";
import { useChart } from "@/composables/useChart";
import { ref } from "vue";
import moment from "moment";
import { useQuery } from "@tanstack/vue-query";
import { HistoricalService } from "@/api/generated";

const props = defineProps({
  saleAddress: {
    type: String,
    required: true,
  },
  height: {
    type: Number,
    default: 200,
  },
});
const loading = ref(true);
const lineSeries = ref<ISeriesApi<"Line">>();

const { data } = useQuery({
  queryFn: () =>
    HistoricalService.getForPreview({ address: props.saleAddress }),
  queryKey: ["HistoricalService.getForPreview", props.saleAddress],
});

const { chartContainer, chart } = useChart({
  height: props.height,
  chartOptions: {
    grid: {
      horzLines: {
        visible: false,
      },
      vertLines: {
        visible: false,
      },
    },
    timeScale: {
      visible: false,
    },
    crosshair: {
      vertLine: {
        visible: false,
      },
      horzLine: {
        visible: false,
      },
    },
  },
  onChartReady: (chart) => {
    const seriesOptions: LineSeriesPartialOptions = {
      priceLineVisible: false,
      color: "rgb(244, 193, 12)",
      lineWidth: 2,
      crosshairMarkerVisible: false,
    };

    lineSeries.value = chart.addLineSeries(seriesOptions);
    lineSeries.value?.priceScale().applyOptions({
      visible: false, // disables auto scaling based on visible content
      ticksVisible: false,
    });
  },
});

watch(data, (newData) => {
  lineSeries.value?.setData([]);

  if (!newData?.result?.length) {
    return;
  }

  const formattedData = newData.result
    .map((item) => {
      return {
        time: moment(item.end_time).unix(),
        value: Number(item.last_price),
      };
    })
    .sort((a, b) => a.time - b.time);

  lineSeries.value?.setData(formattedData);
  chart.value?.timeScale().fitContent();
});
</script>
<style scoped>
.timeframe-indicator {
  position: relative;
  top: -0.5rem;
  right: -70px;
  font-size: 0.6rem;
  text-transform: lowercase;
}
.chart-container {
  margin-right: 8px;
}
.chart-container,
.lw-chart {
  height: 100%;
}
</style>
