<template>
  <v-bottom-navigation id="bottom-navigation" grow fixed v-if="smAndDown">
    <v-btn :to="{ name: 'home' }" exact color="primary">
      <!-- <v-icon>mdi-home</v-icon> -->

      <span>Tokens</span>
    </v-btn>
    <v-btn :to="{ name: 'create-token' }" exact color="primary">
      <!-- <v-icon>mdi-bitcoin</v-icon> -->

      <span>Create Token</span>
    </v-btn>

    <v-btn :to="{ name: 'about' }" exact color="primary">
      <!-- <v-icon>mdi-menu</v-icon> -->

      <span>More</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script lang="ts" setup>
import { useDisplay } from "vuetify/lib/framework.mjs";
const { smAndDown } = useDisplay();
</script>

<style scoped>
#bottom-navigation {
  padding-bottom: env(safe-area-inset-top);
  height: calc(46px + env(safe-area-inset-top)) !important;
}
</style>
