import { nodes } from "@/config";
import { useAccounts } from "@/stores/accounts";
import { useNetworkStore } from "@/stores/network";
import { useTxQueueStore } from "@/stores/txQueue";
import { watchUntilTruthy } from "@/utils/common";
import { IS_MOBILE, NETWORK_MAINNET } from "@/utils/constants";
import { createDeepLinkUrl } from "@/utils/wallet";
import { AeSdk, AeSdkAepp, CompilerHttp } from "@aeternity/aepp-sdk";
import { storeToRefs } from "pinia";
import { ref } from "vue";

let aeSdk: AeSdkAepp;
const staticAeSdk = new AeSdk({
  onCompiler: new CompilerHttp("https://v7.compiler.aepps.com"),
  nodes,
});
const scanningForAccounts = ref(false);

export function useAeppSdk() {
  const { setAccounts, setActiveAccount } = useAccounts();
  const { activeAccount } = storeToRefs(useAccounts());
  const { transactionsQueue } = storeToRefs(useTxQueueStore());
  const { activeNetwork, activeNetworkId } = storeToRefs(useNetworkStore());

  async function scanForAccounts() {
    scanningForAccounts.value = true;
    const currentAddress = Object.keys(
      aeSdk._accounts?.current || {}
    )[0] as any;

    setAccounts([currentAddress]);

    setActiveAccount(currentAddress);
    scanningForAccounts.value = false;
  }

  async function initSdk() {
    aeSdk = new AeSdkAepp({
      name: "AEAPP",
      nodes,
      onCompiler: new CompilerHttp(NETWORK_MAINNET.compilerUrl),
      onNetworkChange: (network: any) => {
        if (network.networkId) {
          activeNetworkId.value = network.networkId;
          console.log("onNetworkChange", network);
        }
      },
      onAddressChange: (a: any) => {
        setActiveAccount(Object.keys(a.current || {})[0] as any);
      },
      onDisconnect: () => {
        setActiveAccount(undefined);
        setAccounts([]);
      },
    });

    if (activeAccount.value) {
      addStaticAccount(activeAccount.value);
    }
  }

  function addStaticAccount(address: any) {
    setActiveAccount(address);
    staticAeSdk.addAccount(
      {
        address,
        signTransaction: function (
          tx: `tx_${string}`,
          options: {
            innerTx?: boolean | undefined;
            networkId?: string | undefined;
          }
        ): Promise<`tx_${string}`> {
          const uniqueId = Math.random().toString(36).substring(7);
          console.log("tx", tx, options);
          const currentUrl = new URL(window.location.href);
          // reset url
          currentUrl.searchParams.delete("transaction");
          currentUrl.searchParams.delete("status");

          const currentDomain = currentUrl.origin;

          // append transaction parameter for success case
          // const successUrl = new URL(currentUrl.href);
          const successUrl = new URL(`${currentDomain}/tx-queue/${uniqueId}`);
          successUrl.searchParams.set("transaction", "{transaction}");
          successUrl.searchParams.set("status", "completed");

          // append transaction parameter for failed case
          const cancelUrl = new URL(`${currentDomain}/tx-queue/${uniqueId}`);
          cancelUrl.searchParams.set("status", "cancelled");

          const signUrl: any = createDeepLinkUrl({
            type: "sign-transaction",
            transaction: tx,
            networkId: activeNetwork.value.networkId,
            "replace-caller": "true",
            // decode these urls because they will be encoded again
            "x-success": decodeURI(successUrl.href),
            "x-cancel": decodeURI(cancelUrl.href),
          });

          transactionsQueue.value[uniqueId] = {
            status: "pending",
            tx,
            signUrl,
          };

          let newWindow: Window | null = null;

          if (IS_MOBILE) {
            const a = document.createElement("a");
            a.href = signUrl;
            a.click();
          } else {
            newWindow = window.open(signUrl, "_blank");
          }

          return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
              if (Object.keys(transactionsQueue.value).includes(uniqueId)) {
                if (transactionsQueue.value[uniqueId]?.status === "cancelled") {
                  clearInterval(interval);
                  reject(new Error("Transaction cancelled"));
                  newWindow?.close();
                  // delete transaction from queue
                  delete transactionsQueue.value[uniqueId];
                }

                if (
                  transactionsQueue.value[uniqueId]?.status === "completed" &&
                  transactionsQueue.value[uniqueId]?.transaction
                ) {
                  clearInterval(interval);
                  resolve(transactionsQueue.value[uniqueId].transaction);
                  newWindow?.close();
                  // delete transaction from queue
                  delete transactionsQueue.value[uniqueId];
                }
              }
            }, 500);
          });
        },
      } as any,
      { select: true }
    );
  }

  /**
   * Get the SDK instance. For now the SDK state is asynchronous.
   */
  async function getSdk(): Promise<AeSdkAepp> {
    return watchUntilTruthy(() => aeSdk);
  }

  /**
   * Get the SDK instance. For now the SDK state is asynchronous.
   */
  async function getAeSdk(): Promise<AeSdkAepp | AeSdk> {
    if (aeSdk._accounts?.current) {
      return aeSdk;
    }
    return staticAeSdk;
  }

  return {
    getSdk,
    getAeSdk,
    aeSdk,
    staticAeSdk,
    initSdk,

    scanningForAccounts,
    addStaticAccount,

    scanForAccounts,
  };
}
