<template>
  <v-card v-if="!!invitationCode" class="mb-4 custom-card">
    <v-card-text>
      <div class="text-h3 pb-4">YOU HAVE BEEN INVITED TO TOKAEN.ORG</div>
      <p>
        Easily create and manage Token Sales with a bonding curve ensuring fair
        pricing. Engage in fair token launches, participate in community
        governance through integrated DAOs, and maximize rewards with a robust
        referral system.
      </p>
      <p>
        Grow vibrant token-gated communities for businesses, projects, DAOs, or
        even meme coins. Step into the future of decentralized token management!
      </p>
      <div class="text-h4 py-4 d-flex ga-1 align-center flex-wrap">
        CLAIM YOUR
        <LivePriceFormatter
          :ae-price="invitationAmount"
          :watch-price="false"
          :price-loading="loadingInvitation"
          class="text-primary text-decoration-underline"
          row
        />
        REWARD
      </div>
      <div class="pb-4 d-flex ga-1 align-center flex-wrap">
        <AddressChip :address="invitationSender" />
        has invited you to join Tokaen.org, you will earn a
        <LivePriceFormatter
          :ae-price="invitationAmount"
          :watch-price="false"
          :price-loading="loadingInvitation"
          row
        />
        <span v-if="!activeAccount">
          reward when connecting your Superhero Wallet.</span
        >
        <span v-else> reward.</span>
      </div>
      <v-alert
        v-if="errorMessage"
        icon="$error"
        color="error"
        class="mb-5"
        border="start"
        title="Oops! Something went wrong."
        :text="errorMessage"
      />
      <v-alert
        v-if="successMessage"
        icon="$success"
        color="success"
        class="mb-5"
        border="start"
        title="Success!"
        :text="successMessage"
      />
      <v-btn
        v-if="activeAccount"
        size="large"
        color="primary"
        :disabled="invitationClaimed"
        :loading="claimingReward"
        @click="claimReward()"
      >
        Claim AE
      </v-btn>

      <WalletConnect
        connect-wallet-text="Connect wallet to claim reward"
        size="large"
      />

      <v-btn
        v-if="activeAccount"
        size="large"
        color="primary"
        variant="outlined"
        class="ml-4"
        @click="onClose()"
      >
        Dismiss
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import WalletConnect from "@/components/WalletConnect/WalletConnect.vue";
import AddressChip from "@/components/Common/AddressChip.vue";

import { useAeppSdk } from "@/composables/aeppSdk";
import { useNetwork } from "@/composables/useNetwork";
import { nodes } from "@/config";
import { Decimal } from "@/libs/decimal";

import { useAccounts } from "@/stores/accounts";
import {
  AeSdk,
  AeSdkAepp,
  CompilerHttp,
  MemoryAccount,
} from "@aeternity/aepp-sdk";
import { storeToRefs } from "pinia";
import { initRoomFactory } from "token-sale-sdk";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useTokenSaleFactory } from "@/composables/useTokenSaleFactory";
import { useInvitation } from "@/composables/useInvitation";

import LivePriceFormatter from "@/components/Common/Pricing/LivePriceFormatter.vue";

const router = useRouter();

const { getAeSdk } = useAeppSdk();
const { activeNetwork } = useNetwork();
const { activeAccount } = storeToRefs(useAccounts());
const { activeTokenSaleFactoryAddress } = useTokenSaleFactory();

const invitationAmount = ref<Decimal>(Decimal.ZERO);
const invitationSender = ref();
const invitationClaimed = ref(false);

const loadingInvitation = ref(false);
const claimingReward = ref(false);

const errorMessage = ref();
const successMessage = ref();

const { invitationCode } = useInvitation();

watch(
  invitationCode,
  (invCode) => {
    if (invCode) {
      getInvitationRewardAmount();
    }
  },
  {
    immediate: true,
  }
);

watch(activeNetwork, () => {
  if (invitationCode.value) {
    getInvitationRewardAmount();
  }
});

/**
 * Retrieves the affiliation contract from the room factory.
 *
 * @param {AeSdk | AeSdkAepp} sdk - The AeSdk or AeSdkAepp instance.
 * @returns {Promise<AffiliationContract>} The affiliation contract.
 */
async function getAffiliationContract(sdk: AeSdk | AeSdkAepp) {
  const factory = await initRoomFactory(
    sdk,
    activeTokenSaleFactoryAddress.value
  );

  const affiliationContract = await factory.affiliationTreasuryContract();

  return affiliationContract;
}

/**
 * Retrieves the invitation reward amount for a given invitation code.
 * This function sets the loading state, initializes the AeSdk, retrieves the affiliation contract,
 * and updates the invitation details if the invitation code exists.
 * If the invitation has already been claimed, it displays an error message and closes the component after 3 seconds.
 * @throws {Error} If there is an error retrieving the invitation reward amount.
 */
async function getInvitationRewardAmount() {
  errorMessage.value = null;
  loadingInvitation.value = true;
  try {
    const account = new MemoryAccount(invitationCode.value);
    const sdk = new AeSdk({
      onCompiler: new CompilerHttp("https://v7.compiler.aepps.com"),
      nodes,
      accounts: [account],
    });
    sdk.selectNode(activeNetwork.value.name);

    const affiliationContract = await getAffiliationContract(sdk);

    const invitation_codes = await affiliationContract
      ?.invitation_codes()
      .then((res) => res.decodedResult)
      .catch((e) => ({}));

    const invitation = invitation_codes.get(account.address);
    if (invitation) {
      invitationSender.value = invitation[0];
      invitationAmount.value = Decimal.fromBigNumberString(invitation[1]);
      invitationClaimed.value = invitation[2];

      if (invitationClaimed.value) {
        errorMessage.value = "This invitation has already been claimed.";

        setTimeout(() => {
          onClose();
        }, 3000);
      }
    }
  } catch (error: any) {
    console.log("ERROR", error);
    if (error?.message?.includes("Trying to call undefined function")) {
      errorMessage.value = "Please switch to the correct network.";
    } else {
      errorMessage.value = error?.message;
    }
  }
  loadingInvitation.value = false;
}

async function claimReward() {
  claimingReward.value = true;
  const sdk = await getAeSdk();
  const affiliationContract = await getAffiliationContract(sdk);

  try {
    await affiliationContract?.redeem_invitation_code(activeAccount.value, {
      onAccount: new MemoryAccount(invitationCode.value),
    });
    successMessage.value = "Reward claimed successfully.";
    invitationClaimed.value = true;
  } catch (error: any) {
    if (error?.message?.includes("INVITEE_ALREADY_REGISTERED")) {
      errorMessage.value =
        "You can't claim this reward with the current active account, try with another account.";
    }
  }
  claimingReward.value = false;
}

function onClose() {
  localStorage.removeItem("invite_code");
  router.push({ query: { invite_code: null } });
  invitationCode.value = null;
}
</script>
