import { Node } from "@aeternity/aepp-sdk";
import { NETWORK_MAINNET, NETWORK_TESTNET } from "./utils/constants";

export const WALLET_URL =
  process.env.VUE_APP_WALLET_URL ?? "https://wallet.superhero.com";

export const APP_URL =
  process.env.VUE_APP_APP_URL ?? "https://aedictive-dev.aepps.com";

export const SHOW_COUNTDOWN = process.env.VUE_APP_SHOW_COUNTDOWN === "true";

export const nodes: { instance: Node; name: string }[] = [
  {
    name: NETWORK_MAINNET.name,
    instance: new Node(NETWORK_MAINNET.url),
  },
  {
    name: NETWORK_TESTNET.name,
    instance: new Node(NETWORK_TESTNET.url),
  },
];
