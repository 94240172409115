import {
  NETWORK_ID_MAINNET,
  NETWORK_ID_TESTNET,
  ROOM_FACTORY_CONTRACTS,
} from "@/utils/constants";
import { INetworkTypes } from "@/utils/types";
import { Contract, ContractMethodsBase, Encoded } from "@aeternity/aepp-sdk";
import { RoomFactory, initRoomFactory } from "token-sale-sdk";
import { computed, ref } from "vue";
import { useAeppSdk } from "./aeppSdk";
import { useNetwork } from "./useNetwork";

export interface ITokenSaleFactory {
  factory: RoomFactory;
  address: Encoded.ContractAddress;
  bondingCurveAddress: Encoded.ContractAddress;
  affiliationContract: Contract<ContractMethodsBase> | null;
}

const tokenSaleFactories = ref<
  Record<INetworkTypes, Record<Encoded.ContractAddress, ITokenSaleFactory>>
>({
  [NETWORK_ID_MAINNET]: {},
  [NETWORK_ID_TESTNET]: {},
});

const factoriesLoaded = ref(false);

export function useTokenSaleFactory() {
  const { getAeSdk } = useAeppSdk();
  const { activeNetworkId } = useNetwork();

  const factoryContracts = computed(
    () => ROOM_FACTORY_CONTRACTS[activeNetworkId.value]
  );

  const activeTokenSaleFactoryAddress = computed(() => {
    const contracts = ROOM_FACTORY_CONTRACTS[activeNetworkId.value];
    if (!contracts?.length) {
      return undefined;
    }
    return contracts[contracts.length - 1].contractId;
  });

  const activeTokenSaleFactory = computed(() => {
    if (
      !activeTokenSaleFactoryAddress.value ||
      !tokenSaleFactories.value[activeNetworkId.value]
    ) {
      return undefined;
    }

    return tokenSaleFactories.value[activeNetworkId.value][
      activeTokenSaleFactoryAddress.value
    ];
  });

  async function loadFactory(address: Encoded.ContractAddress) {
    const sdk = await getAeSdk();
    const factory = await initRoomFactory(sdk, address);

    if (!factory) {
      return null;
    }
    const [bondingCurveAddress, affiliationContract] = await Promise.all([
      factory.bondingCurveAddress?.(),
      factory.affiliationTreasuryContract?.(),
    ]);
    const tokenSaleFactory = {
      address,
      factory,
      bondingCurveAddress,
      affiliationContract,
    };
    tokenSaleFactories.value[activeNetworkId.value][address] = tokenSaleFactory;
    return tokenSaleFactory;
  }

  async function loadFactories() {
    factoriesLoaded.value = false;
    const contracts = ROOM_FACTORY_CONTRACTS[activeNetworkId.value];
    await Promise.all(
      contracts.map((contract) => loadFactory(contract.contractId))
    );
    factoriesLoaded.value = true;
  }

  // reload active factory
  async function reloadActiveFactory() {
    if (!activeTokenSaleFactoryAddress.value) {
      return;
    }
    await loadFactory(activeTokenSaleFactoryAddress.value);
  }

  async function initTokenSaleFactory() {
    try {
      await loadFactories();
    } catch (error) {
      //
    }
  }

  return {
    tokenSaleFactories,
    initTokenSaleFactory,

    factoriesLoaded,
    loadFactory,
    loadFactories,
    reloadActiveFactory,

    factoryContracts,

    activeTokenSaleFactory,
    activeTokenSaleFactoryAddress,
  };
}
