<template>
  <v-chip
    v-if="address"
    size="small"
    :href="prepareUrlForHash(address)"
    target="_blank"
  >
    <v-avatar v-if="!hideAvatar" start>
      <v-img :src="`https://avatars.z52da5wt.xyz/${address}`"></v-img>
    </v-avatar>
    {{ formatAddress(address) }}
    <v-avatar v-if="link" end>
      <v-icon>mdi-open-in-new</v-icon>
    </v-avatar>
  </v-chip>
</template>

<script lang="ts" setup>
import { useAeScan } from "@/composables/useAeScan";
import { formatAddress } from "@/utils/address";
import { defineProps } from "vue";

const props = defineProps<{
  address: string;
  hideAvatar?: boolean;
  link?: boolean;
}>();

const { prepareUrlForHash } = useAeScan();
</script>
