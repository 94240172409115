import {
  BrowserWindowMessageConnection,
  SUBSCRIPTION_TYPES,
  isAddressValid,
  walletDetector,
} from "@aeternity/aepp-sdk";

import { useAeppSdk } from "./aeppSdk";
import { ref, watch } from "vue";
import { useAccounts } from "@/stores/accounts";
import { storeToRefs } from "pinia";
import { Wallet, Wallets } from "@/utils/types";
import { IS_FRAMED_AEPP, IS_MOBILE, IS_SAFARI } from "@/utils/constants";
import { createDeepLinkUrl } from "@/utils/wallet";
import { useRoute, useRouter } from "vue-router";
import { useNetworkStore } from "@/stores/network";
import { useTokenSaleFactory } from "./useTokenSaleFactory";

const walletConnected = ref(false);

const scanningForWallets = ref(false);
const scanningForAccounts = ref(false);

export function useWalletConnect() {
  const route = useRoute();
  const router = useRouter();
  const { getSdk, scanForAccounts, addStaticAccount } = useAeppSdk();
  const { setAccounts, setActiveAccount } = useAccounts();
  const { activeAccount, walletInfo } = storeToRefs(useAccounts());
  const { activeNetworkId } = storeToRefs(useNetworkStore());
  const { initTokenSaleFactory } = useTokenSaleFactory();

  watch(
    () => route.query,
    (query) => {
      if (query.address && !activeAccount.value) {
        const address = query.address as string;
        if (query.networkId) {
          activeNetworkId.value = query.networkId as any;
        }
        if (!isAddressValid(address)) {
          alert("Invalid Aeternity address");
          router.push({ query: {} });
          return;
        }
        addStaticAccount(address);
        initTokenSaleFactory();
        router.push({ query: {} });
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );

  async function subscribeAddress() {
    const aeSdk = await getSdk();

    return new Promise((resolve, reject) => {
      scanningForAccounts.value = true;
      const $timeout = setTimeout(() => {
        scanningForAccounts.value = false;
        reject();
      }, 10000);

      (async () => {
        try {
          await aeSdk.subscribeAddress(
            SUBSCRIPTION_TYPES.subscribe,
            "connected"
          );
          await scanForAccounts();

          resolve(true);
        } catch (error) {
          reject(error);
        } finally {
          scanningForAccounts.value = false;
          clearTimeout($timeout);
        }
      })();
    });
  }

  async function deepLinkWalletConnect() {
    const addressDeepLink = createDeepLinkUrl({
      type: "address",
      "x-success": `${
        window.location.href.split("?")[0]
      }?address={address}&networkId={networkId}`,
      "x-cancel": window.location.href.split("?")[0],
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.location = addressDeepLink;
  }

  async function connectWallet() {
    const wallet = await scanForWallets();

    if (!wallet) {
      return !IS_FRAMED_AEPP ? deepLinkWalletConnect() : null;
    }

    const aeSdk = await getSdk();
    try {
      const _walletInfo = await aeSdk.connectToWallet(wallet.getConnection());
      walletInfo.value = _walletInfo;

      await subscribeAddress();
      walletConnected.value = true;
      initTokenSaleFactory();
    } catch (error) {
      console.log("wallet connect error ::", error);
      disconnectWallet();
    }
  }

  async function disconnectWallet() {
    walletInfo.value = undefined;

    const aeSdk = await getSdk();
    walletConnected.value = false;
    walletInfo.value = undefined;
    setActiveAccount(undefined);
    setAccounts([]);
    try {
      await aeSdk.disconnectWallet();
    } catch (error) {
      //
    }
    initTokenSaleFactory();
  }

  /**
   * Scan for wallets
   */
  async function scanForWallets(): Promise<Wallet | undefined> {
    scanningForWallets.value = true;
    const foundWallet: Wallet | undefined = await new Promise((resolve) => {
      const $walletConnectTimeout = setTimeout(
        () => {
          resolve(undefined);
        },
        (IS_MOBILE || IS_SAFARI) && !IS_FRAMED_AEPP ? 100 : 15000
      );

      const handleWallets = async ({
        newWallet,
      }: {
        newWallet?: Wallet | undefined;
        wallets: Wallets;
      }) => {
        clearTimeout($walletConnectTimeout);
        stopScan();
        resolve(newWallet);
      };

      const scannerConnection = new BrowserWindowMessageConnection({
        debug: true,
      });
      const stopScan = walletDetector(scannerConnection, handleWallets);
    });

    scanningForWallets.value = false;

    return foundWallet;
  }

  async function checkWalletConnection() {
    if (
      route.name !== "tx-queue" &&
      walletInfo?.value &&
      activeAccount.value &&
      !walletConnected.value
    ) {
      connectWallet();
    }
  }

  return {
    walletInfo,
    checkWalletConnection,

    connectWallet,
    deepLinkWalletConnect,
    disconnectWallet,
    scanForWallets,
    scanningForWallets,
    scanningForAccounts,
  };
}
