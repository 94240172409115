import { useNetworkStore } from "@/stores/network";
import { validateHash } from "@/utils/common";
import { Encoding } from "@aeternity/aepp-sdk";
import { storeToRefs } from "pinia";

/**
 * @link https://aeternity-blockchain.atlassian.net/wiki/spaces/AID/pages/127140445/Paths+map+for+migration+Explorer+-+Scan
 */
const ADDRESS_TYPES: Record<string, string> = {
  [Encoding.AccountAddress]: "accounts",
  [Encoding.ContractAddress]: "contracts",
  [Encoding.Name]: "names",
  [Encoding.OracleAddress]: "oracles/queries",
  [Encoding.TxHash]: "transactions",
};

export function useAeScan() {
  const { activeNetwork } = storeToRefs(useNetworkStore());

  function getEndpointByHash(hash: string) {
    const { valid, prefix } = validateHash(hash);
    if (valid && prefix) {
      return ADDRESS_TYPES[prefix];
    }
    return undefined;
  }

  function prepareUrlForHash(hash: string): string | undefined {
    const endpoint = getEndpointByHash(hash);
    return endpoint
      ? `${activeNetwork.value.explorerUrl}/${endpoint}/${hash}`
      : undefined;
  }

  return {
    getEndpointByHash,
    prepareUrlForHash,
  };
}
